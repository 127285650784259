<template>
  <div>
    <validation-observer
      ref="domainAddForm"
      #default="{invalid}"
    >
      <b-modal
        id="add-modal"
        ref="add-modal"
        no-close-on-backdrop
        ok-title="確認"
        centered
        header-bg-variant="primary"
        ok-only
        @hidden="handleHide"
        @close="handleHide"
        @ok="handleOk"
      >
        <template #modal-title>
          <h4 class="m-0 model-header">
            匯入網域
          </h4>
        </template>

        <div>
          <b-form
            @reset.prevent="resetForm"
            @submit.prevent="handleOk"
          >
            <b-row>
              <b-col cols="12">

                <b-form-group
                  label="分站"
                  label-for="branch_id"
                >
                  <template #label>
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="mb-0">
                        分站
                        <span class="text-danger">*</span>
                      </label>
                    </div>
                  </template>

                  <v-select
                    v-model="domainData.branch_id"
                    :options="domainBranchOptions"
                    :reduce="option => option.id"
                    :clearable="false"
                    label="branch"
                    class="edit-col-select"
                    placeholder="請選擇分站"
                    @input="selectedBranchId"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        沒有<em>{{ search }}</em> 的相關結果
                      </template>

                      <div v-else>
                        <small>暫無資料</small>
                      </div>
                    </template>

                    <template v-slot:option="option">
                      <div>
                        <div
                          v-if="option.name"
                          class="font-weight-bold d-block text-nowrap mb-0"
                        >
                          {{ option.name }}<small class="text-muted">( {{ option.branch }} )</small>
                        </div>

                        <div v-else>
                          {{ option.branch }}
                        </div>
                      </div>
                    </template>

                    <template #selected-option="option">
                      <div>
                        <div
                          v-if="option.name"
                          class="font-weight-bold d-block text-nowrap mb-0"
                        >
                          {{ option.name }}<small class="text-muted">( {{ option.branch }} )</small>
                        </div>

                        <div v-else>
                          {{ option.branch }}
                        </div>
                      </div>
                    </template>
                  </v-select>
                </b-form-group>

              </b-col>
              <b-col
                cols="12"
                md="7"
              >
                <!-- 網域前綴 -->
                <b-form-group label-for="prefix">
                  <template #label>
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="mb-0">
                        網域前綴
                        <span class="text-danger">*</span>
                      </label>
                    </div>
                  </template>

                  <validation-provider
                    #default="{ errors }"
                    name="網域前綴"
                    rules="required"
                  >
                    <b-form-input
                      id="prefix"
                      v-model="domainData.prefix"
                      autofocus
                      trim
                      placeholder="請輸入網域前綴"
                    />
                    <small
                      v-if="errors[0]"
                      class="ml-25 text-danger"
                    >
                      {{ errors[0] }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- 網域後綴 -->
              <b-col
                cols="12"
                md="5"
              >
                <b-form-group
                  label="網域後綴"
                  label-for="suffix"
                >
                  <template #label>
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="mb-0">
                        網域後綴
                        <span class="text-danger">*</span>
                      </label>
                    </div>
                  </template>

                  <v-select
                    v-model="domainData.suffix"
                    :options="domainProductOptionsFilter"
                    :clearable="false"
                    input-id="id"
                    placeholder="請選擇網域後綴"
                    class="select-type"
                    label="suffix"
                    :disabled="!domainData.branch_id"
                    :reduce="option => option.suffix"
                  >

                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        沒有<em>{{ search }}</em> 的相關結果
                      </template>

                      <div v-else>
                        <small>暫無資料</small>
                      </div>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <!-- 會員 -->
              <b-col cols="12">
                <b-form-group>
                  <template #label>
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="mb-0">
                        會員
                        <!-- <span class="text-danger">*</span> -->
                      </label>
                    </div>
                  </template>

                  <div
                    class="d-flex justify-content-start align-items-center cursor-pointer border p-1"
                    @click="submitCustomerChoice"
                  >
                    <div class="mr-1">
                      <b-avatar
                        size="40"
                        :src="domainData.customer_info.image"
                      />
                    </div>
                    <div
                      v-if="domainData.customer_info.id"
                      class="profile-user-info"
                    >
                      <h6 class="mb-0">
                        <span class="mr-50">{{ domainData.customer_info.name }}{{ domainData.customer_info.family_name }}</span>
                      </h6>

                      <small
                        class="text-muted"
                        style="word-break: break-all"
                      > {{ domainData.customer_info.account }}</small>
                    </div>

                    <div v-else>
                      <h6 class="mb-0">
                        <span class="mr-50">新增會員</span>
                      </h6>
                    </div>
                  </div>
                </b-form-group>
              </b-col>

              <!-- 網域商 -->
              <b-col
                cols="12"
                lg="12"
              >
                <b-form-group>
                  <template #label>
                    <div class="d-flex justify-content-between align-items-center">
                      <label
                        for="searchAgentId"
                        class="mb-0"
                      >
                        網域商
                        <span class="text-danger">*</span>
                      </label>
                    </div>
                  </template>

                  <v-select
                    v-model="searchAgentId"
                    :options="domainAgentOptions"
                    class="w-100"
                    label="agent"
                    :clearable="false"
                    :reduce="option => option.id"
                    @input="() => {
                      getDomainDealerData()
                      domainData.domain_name_dealer_id = null
                    }"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        沒有<em>{{ search }}</em> 的相關結果
                      </template>

                      <div v-else>
                        <small>暫無資料</small>
                      </div>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <!-- 經銷商 -->
              <b-col
                cols="12"
                lg="12"
              >
                <b-form-group>
                  <template #label>
                    <div class="d-flex justify-content-between align-items-center">
                      <label
                        for="domain_name_dealer_id"
                        class="mb-0"
                      >
                        經銷商
                        <span class="text-danger">*</span>
                      </label>
                    </div>
                  </template>

                  <v-select
                    v-model="domainData.domain_name_dealer_id"
                    :options="domainDealerOptions"
                    class="w-100"
                    label="name"
                    :clearable="false"
                    :reduce="option => option.id"
                    :disabled="!searchAgentId"
                    placeholder="請選擇經銷商"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        沒有<em>{{ search }}</em> 的相關結果
                      </template>

                      <div v-else>
                        <small>暫無資料</small>
                      </div>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <!-- 說明 -->
              <b-col cols="12">
                <div>
                  <label>
                    說明
                    <feather-icon
                      v-b-tooltip.hover.focus.v-secondary
                      title="會員可自行針對網域用途編輯說明"
                      icon="UsersIcon"
                      size="12"
                      style="margin-top: -5px;"
                    />
                  </label>
                  <b-form-textarea
                    v-model="domainData.content"
                    placeholder="請輸入說明"
                    :state="domainData.content.length <= maxChar ? null : false"
                    rows="2"
                  />
                  <small
                    class="textarea-counter-value float-right"
                    :class="domainData.content.length > maxChar ? 'bg-danger' : ''"
                  >
                    <span class="char-count">{{ domainData.content.length }}</span> / {{ maxChar }}
                  </small>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </div>

        <template #modal-footer>
          <div class="w-100 text-right">
            <b-button
              variant="primary"
              :disabled="invalid || isBusy"
              @click="handleOk"
            >
              <span v-if="!isBusy">確認</span>

              <b-spinner
                v-else
                small
              />
            </b-button>
          </div>
        </template>

        <!-- {{ domainData }} -->
      </b-modal>
    </validation-observer>

    <admin-customer-modal
      ref="adminCustomerExplorerModal"
      branch-explorer-id="adminCustomerExplorerModal"
      :filter-branch="domainData.branch_id"
      :is-hiden-emit="false"
      :use-callback-data="true"
      @emit-hidden-function="() => {}"
      @call-back-data="callbacCustomerExplorer"
    />
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BModal, BForm, BRow, BCol, VBTooltip, BButton, BSpinner, BFormGroup, BFormInput, BFormTextarea, BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { useSwalToast, useAlert } from '@/libs/mixins/index'
import AdminCustomerModal from '@/layouts/components/Modal/admin-customer-modal/customerModal.vue'
import { useDomainServiceList, useDomainSetting } from '../useDomain'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BModal,
    BAvatar,
    BButton,
    BSpinner,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    ValidationObserver,
    ValidationProvider,
    vSelect,
    AdminCustomerModal,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  data() {
    return {
      missingFields: [],
      maxChar: 255,
      required,
    }
  },
  computed: {
    domainProductOptionsFilter() {
      return this.domainProductOptions
        .filter(f => f.branch_id && f.branch_id === this.domainData.branch_id)
    },
  },
  methods: {
    // (選擇)分站
    selectedBranchId() {
      const options = this.domainProductOptionsFilter
      // 更換分站要清空user
      const customerInfo = {
        id: null,
        ssid: null,
        name: null,
        family_name: null,
        account: null,
        image: null,
      }

      this.domainData.customer_info = JSON.parse(JSON.stringify(customerInfo))
      this.domainData.customer = null

      if (options.length > 0) {
        const [firstDomain] = options
        this.domainData.suffix = firstDomain.suffix
      } else {
        this.domainData.suffix = null
      }
    },

    // 按下彈窗關閉
    handleHide(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.resetDomainData()
      this.isBusy = false
      this.$nextTick(() => {
        this.$bvModal.hide('add-modal')
      })
    },

    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()

      if (this.isBusy) return

      const requiredFields = {
        prefix: '網域前綴',
        suffix: '網域後綴 ',
        domain_name_dealer_id: '經銷商',
      }

      this.missingFields = []

      for (let i = 0; i < Object.keys(requiredFields).length; i += 1) {
        if (this.domainData[Object.keys(requiredFields)[i]] === null || this.domainData[Object.keys(requiredFields)[i]] === '') {
          this.missingFields.push({
            label: Object.values(requiredFields)[i],
            value: Object.keys(requiredFields)[i],
          })
        }
      }

      if (this.missingFields.length > 0) {
        this.useAlertToast(false, `${this.missingFields[0].label}不得為空`)
        return
      }
      const resolveData = {
        prefix: this.domainData.prefix,
        suffix: this.domainData.suffix,
        content: this.domainData.content,
        customer: this.domainData.customer,
        domain_name_dealer_id: this.domainData.domain_name_dealer_id,
      }

      this.isBusy = true
      this.$refs.domainAddForm.validate()
        .then(success => {
          if (success) {
            this.setDomainServiceAdd(resolveData)
              .then(response => {
                this.useAlertToast(response.data.success, response.data.message)
                this.isBusy = false
                this.$emit('refetch-data')
                this.resetDomainData()
                this.$nextTick(() => {
                  this.$bvModal.hide('add-modal')
                })
              })
              .catch(error => {
                this.useHttpCodeAlert(error.response)
                this.isBusy = false
              })
          } else {
            this.useAlertToast(false, '資料填寫不完全')
            this.isBusy = false
          }
        })
    },

    // (觸發)會員選擇
    submitCustomerChoice() {
      if (!this.domainData.branch_id) {
        this.useAlertToast(false, '分站尚未設定')
        return
      }
      this.$refs.adminCustomerExplorerModal.getData(this.domainData.customer_info)
    },

    // (回傳)所選擇的會員資料
    callbacCustomerExplorer(item) {
      this.domainData.customer_info.id = item.id
      this.domainData.customer_info.ssid = item.ssid
      this.domainData.customer_info.name = item.name
      this.domainData.customer_info.family_name = item.family_name
      this.domainData.customer_info.account = item.account
      this.domainData.customer_info.image = item.image
      this.domainData.customer = item.ssid
    },

    // (開啟)新增金流商
    getData() {
      this.$bvModal.show('add-modal')
      this.getBranchData()
      this.getDomainNamePrice()
      this.getDomainAgentData()
    },
  },
  setup() {
    const {
      syncObject,
      getMetaListData,
    } = useDomainSetting()

    const {
      isBusy,
      setDomainServiceAdd,
    } = useDomainServiceList()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const domainData = ref(null)
    const searchAgentId = ref(null)
    const domainAgentOptions = ref([])
    const domainDealerOptions = ref([])
    const domainProductOptions = ref([])
    const domainBranchOptions = ref([])

    const blankDomainData = {
      branch_id: null,
      prefix: null,
      suffix: null,
      customer: null,
      domain_name_dealer_id: null,
      content: '',
      customer_info: {
        id: null,
        ssid: null,
        name: null,
        family_name: null,
        account: null,
        image: null,
      },
    }

    domainData.value = JSON.parse(JSON.stringify(blankDomainData))

    const resetDomainData = () => {
      domainData.value = JSON.parse(JSON.stringify(blankDomainData))
    }

    const getDomainDealerData = () => {
      let resolveFilters = ''

      if (searchAgentId.value !== null) {
        resolveFilters = `domain_name_agent_id: ${searchAgentId.value}`
      }

      const resolveQueryParams = {
        filters: resolveFilters,
        noPagination: true,
        _: Date.now(),
      }

      getMetaListData({ key: 'domainNameDealer', queryParams: { ...resolveQueryParams } })
        .then(response => {
          const { data } = response.data.data
          domainDealerOptions.value = data.map(item => {
            const resolve = {
              ...syncObject({ id: null, name: null }, item),
            }
            return resolve
          })
        })
    }

    const getDomainAgentData = () => {
      getMetaListData({ key: 'domainNameAgent', queryParams: { noPagination: true } }) //
        .then(response => {
          const { data } = response.data.data
          domainAgentOptions.value = data.map(item => {
            const resolve = {
              ...syncObject({ id: null, agent: null }, item),
            }
            return resolve
          })
          if (domainAgentOptions.value.length > 0) {
            const [firstAgent] = domainAgentOptions.value
            searchAgentId.value = firstAgent.id
            getDomainDealerData()
          }
        })
    }

    const getDomainNamePrice = () => {
      getMetaListData({ key: 'domainNamePrice', queryParams: { noPagination: true } }) //
        .then(response => {
          const { data } = response.data.data
          domainProductOptions.value = data.map(item => {
            const resolve = {
              ...syncObject({ id: null, suffix: null, branch_id: null }, item),
            }
            return resolve
          })
        })
    }

    const getBranchData = () => {
      getMetaListData({ key: 'branch', queryParams: { noPagination: true } }) //
        .then(response => {
          const { data } = response.data.data
          domainBranchOptions.value = data.map(item => {
            const resolve = {
              ...syncObject({
                id: null,
                name: null,
                branch: null,
              }, item),
            }
            return resolve
          })

          if (domainBranchOptions.value.length > 0) {
            const [firstBranch] = domainBranchOptions.value
            domainData.value.branch_id = firstBranch.id
          }
        })
    }

    return {
      isBusy,
      useAlertToast,
      useHttpCodeAlert,
      searchAgentId,
      domainAgentOptions,
      domainDealerOptions,
      domainProductOptions,
      domainBranchOptions,
      domainData,
      blankDomainData,
      setDomainServiceAdd,
      getDomainDealerData,
      getDomainAgentData,
      getDomainNamePrice,
      getBranchData,
      resetDomainData,

      syncObject,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}

.loading-area {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}
</style>
