var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"domainAddForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-modal',{ref:"add-modal",attrs:{"id":"add-modal","no-close-on-backdrop":"","ok-title":"確認","centered":"","header-bg-variant":"primary","ok-only":""},on:{"hidden":_vm.handleHide,"close":_vm.handleHide,"ok":_vm.handleOk},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h4',{staticClass:"m-0 model-header"},[_vm._v(" 匯入網域 ")])]},proxy:true},{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100 text-right"},[_c('b-button',{attrs:{"variant":"primary","disabled":invalid || _vm.isBusy},on:{"click":_vm.handleOk}},[(!_vm.isBusy)?_c('span',[_vm._v("確認")]):_c('b-spinner',{attrs:{"small":""}})],1)],1)]},proxy:true}],null,true)},[_c('div',[_c('b-form',{on:{"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)},"submit":function($event){$event.preventDefault();return _vm.handleOk.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"分站","label-for":"branch_id"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('label',{staticClass:"mb-0"},[_vm._v(" 分站 "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])])]},proxy:true}],null,true)},[_c('v-select',{staticClass:"edit-col-select",attrs:{"options":_vm.domainBranchOptions,"reduce":function (option) { return option.id; },"clearable":false,"label":"branch","placeholder":"請選擇分站"},on:{"input":_vm.selectedBranchId},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
return [(searching)?[_vm._v(" 沒有"),_c('em',[_vm._v(_vm._s(search))]),_vm._v(" 的相關結果 ")]:_c('div',[_c('small',[_vm._v("暫無資料")])])]}},{key:"option",fn:function(option){return [_c('div',[(option.name)?_c('div',{staticClass:"font-weight-bold d-block text-nowrap mb-0"},[_vm._v(" "+_vm._s(option.name)),_c('small',{staticClass:"text-muted"},[_vm._v("( "+_vm._s(option.branch)+" )")])]):_c('div',[_vm._v(" "+_vm._s(option.branch)+" ")])])]}},{key:"selected-option",fn:function(option){return [_c('div',[(option.name)?_c('div',{staticClass:"font-weight-bold d-block text-nowrap mb-0"},[_vm._v(" "+_vm._s(option.name)),_c('small',{staticClass:"text-muted"},[_vm._v("( "+_vm._s(option.branch)+" )")])]):_c('div',[_vm._v(" "+_vm._s(option.branch)+" ")])])]}}],null,true),model:{value:(_vm.domainData.branch_id),callback:function ($$v) {_vm.$set(_vm.domainData, "branch_id", $$v)},expression:"domainData.branch_id"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"7"}},[_c('b-form-group',{attrs:{"label-for":"prefix"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('label',{staticClass:"mb-0"},[_vm._v(" 網域前綴 "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"網域前綴","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"prefix","autofocus":"","trim":"","placeholder":"請輸入網域前綴"},model:{value:(_vm.domainData.prefix),callback:function ($$v) {_vm.$set(_vm.domainData, "prefix", $$v)},expression:"domainData.prefix"}}),(errors[0])?_c('small',{staticClass:"ml-25 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"5"}},[_c('b-form-group',{attrs:{"label":"網域後綴","label-for":"suffix"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('label',{staticClass:"mb-0"},[_vm._v(" 網域後綴 "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])])]},proxy:true}],null,true)},[_c('v-select',{staticClass:"select-type",attrs:{"options":_vm.domainProductOptionsFilter,"clearable":false,"input-id":"id","placeholder":"請選擇網域後綴","label":"suffix","disabled":!_vm.domainData.branch_id,"reduce":function (option) { return option.suffix; }},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
return [(searching)?[_vm._v(" 沒有"),_c('em',[_vm._v(_vm._s(search))]),_vm._v(" 的相關結果 ")]:_c('div',[_c('small',[_vm._v("暫無資料")])])]}}],null,true),model:{value:(_vm.domainData.suffix),callback:function ($$v) {_vm.$set(_vm.domainData, "suffix", $$v)},expression:"domainData.suffix"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('label',{staticClass:"mb-0"},[_vm._v(" 會員 ")])])]},proxy:true}],null,true)},[_c('div',{staticClass:"d-flex justify-content-start align-items-center cursor-pointer border p-1",on:{"click":_vm.submitCustomerChoice}},[_c('div',{staticClass:"mr-1"},[_c('b-avatar',{attrs:{"size":"40","src":_vm.domainData.customer_info.image}})],1),(_vm.domainData.customer_info.id)?_c('div',{staticClass:"profile-user-info"},[_c('h6',{staticClass:"mb-0"},[_c('span',{staticClass:"mr-50"},[_vm._v(_vm._s(_vm.domainData.customer_info.name)+_vm._s(_vm.domainData.customer_info.family_name))])]),_c('small',{staticClass:"text-muted",staticStyle:{"word-break":"break-all"}},[_vm._v(" "+_vm._s(_vm.domainData.customer_info.account))])]):_c('div',[_c('h6',{staticClass:"mb-0"},[_c('span',{staticClass:"mr-50"},[_vm._v("新增會員")])])])])])],1),_c('b-col',{attrs:{"cols":"12","lg":"12"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('label',{staticClass:"mb-0",attrs:{"for":"searchAgentId"}},[_vm._v(" 網域商 "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])])]},proxy:true}],null,true)},[_c('v-select',{staticClass:"w-100",attrs:{"options":_vm.domainAgentOptions,"label":"agent","clearable":false,"reduce":function (option) { return option.id; }},on:{"input":function () {
                    _vm.getDomainDealerData()
                    _vm.domainData.domain_name_dealer_id = null
                  }},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
                  var search = ref.search;
                  var searching = ref.searching;
return [(searching)?[_vm._v(" 沒有"),_c('em',[_vm._v(_vm._s(search))]),_vm._v(" 的相關結果 ")]:_c('div',[_c('small',[_vm._v("暫無資料")])])]}}],null,true),model:{value:(_vm.searchAgentId),callback:function ($$v) {_vm.searchAgentId=$$v},expression:"searchAgentId"}})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"12"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('label',{staticClass:"mb-0",attrs:{"for":"domain_name_dealer_id"}},[_vm._v(" 經銷商 "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])])]},proxy:true}],null,true)},[_c('v-select',{staticClass:"w-100",attrs:{"options":_vm.domainDealerOptions,"label":"name","clearable":false,"reduce":function (option) { return option.id; },"disabled":!_vm.searchAgentId,"placeholder":"請選擇經銷商"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
                  var search = ref.search;
                  var searching = ref.searching;
return [(searching)?[_vm._v(" 沒有"),_c('em',[_vm._v(_vm._s(search))]),_vm._v(" 的相關結果 ")]:_c('div',[_c('small',[_vm._v("暫無資料")])])]}}],null,true),model:{value:(_vm.domainData.domain_name_dealer_id),callback:function ($$v) {_vm.$set(_vm.domainData, "domain_name_dealer_id", $$v)},expression:"domainData.domain_name_dealer_id"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('div',[_c('label',[_vm._v(" 說明 "),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.focus.v-secondary",modifiers:{"hover":true,"focus":true,"v-secondary":true}}],staticStyle:{"margin-top":"-5px"},attrs:{"title":"會員可自行針對網域用途編輯說明","icon":"UsersIcon","size":"12"}})],1),_c('b-form-textarea',{attrs:{"placeholder":"請輸入說明","state":_vm.domainData.content.length <= _vm.maxChar ? null : false,"rows":"2"},model:{value:(_vm.domainData.content),callback:function ($$v) {_vm.$set(_vm.domainData, "content", $$v)},expression:"domainData.content"}}),_c('small',{staticClass:"textarea-counter-value float-right",class:_vm.domainData.content.length > _vm.maxChar ? 'bg-danger' : ''},[_c('span',{staticClass:"char-count"},[_vm._v(_vm._s(_vm.domainData.content.length))]),_vm._v(" / "+_vm._s(_vm.maxChar)+" ")])],1)])],1)],1)],1)])]}}])}),_c('admin-customer-modal',{ref:"adminCustomerExplorerModal",attrs:{"branch-explorer-id":"adminCustomerExplorerModal","filter-branch":_vm.domainData.branch_id,"is-hiden-emit":false,"use-callback-data":true},on:{"emit-hidden-function":function () {},"call-back-data":_vm.callbacCustomerExplorer}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }